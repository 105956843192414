<template>
  <div class="promo-wrapper">
    <div class="back" @click="closePromotion">
      <BackArrow />
    </div>
    <div class="promotion-detail">
      <div class="card">
        <div class="card-top">
          <img :src="getImageSrc(promotion.img)" alt="Promotion Image" />
        </div>
        <div class="card-center">
          <span>{{ $t(promotion.category) }}</span>
        </div>
        <div class="content">
          <p class="content-detail">{{ $t(promotion.details) }}</p>
        </div>
      </div>
    </div>
    <div class="howtoparticipate">
      <div class="heading">
        <p>{{ $t("howToParticipate") }}</p>
      </div>
      <div class="steps">
        <div v-for="(step, index) in promotion.steps" :key="index">
          <p>{{ $t(`step`, { number: index + 1 }) }}</p>
          <p>{{ $t(step.step) }}</p>
        </div>
      </div>

      <div
        v-if="promotion.key === 'freebet'"
        class="btn"
        @click="handleRegister"
      >
        <ChopbetButton :size="'small'">{{ $t("playGames") }}</ChopbetButton>
      </div>
      <div
        v-else-if="promotion.category === 'casino'"
        class="btn"
        @click="handlePlayGame"
      >
        <ChopbetButton :size="'small'">{{ $t("playGames") }}</ChopbetButton>
      </div>
      <div v-else class="btn" @click="handleRegister">
        <ChopbetButton :size="'small'">{{
          isLoggedIn ? $t("depositNow") : $t("registerNow")
        }}</ChopbetButton>
      </div>
    </div>
    <div class="termsandcondition">
      <p>{{ $t("termsConditions") }}</p>
      <span v-html="formatText($t(promotion.termsAndCondition))"></span>
    </div>
  </div>
</template>

<script>
import ChopbetButton from "../../components/ui/ChopbetButton.vue";
import BackArrow from "../../components/icons/BackArrow.vue";

export default {
  name: "GiftDetail",
  // props: {
  //   promotion: {
  //     type: Object,
  //     required: true,
  //   },
  // },
  components: { ChopbetButton, BackArrow },
  data() {
    return {
      myProfile: this.getProfile(),
      steps: ["step1", "step2", "step3"],
      promotions: [
        {
          id: 1,
          description: "100description",
          img: {
            en: "/img/banners/100-en.webp",
            fr: "/img/banners/100-fr.webp",
          },
          category: "sports",
          key: "100%",
          details: "100Steps",
          steps: [
            {
              step: "step1",
            },
            {
              step: "100Steps2",
            },
            {
              step: "100Steps3",
            },
          ],
          route: `/deposit`,
          termsAndCondition: "100TermsCondition",
        },
        // {
        //   id: 7,
        //   description: "aviatorDescription",
        //   img: {
        //     en: "/img/banners/aviator-en.webp",
        //     fr: "/img/banners/aviator-fr.webp",
        //   },
        //   category: "casino",
        //   key: "aviator",
        //   details: "aviatorStep",
        //   steps: [
        //     {
        //       step: "aviatorStep1",
        //     },
        //     {
        //       step: "aviatorStep2",
        //     },
        //     {
        //       step: "aviatorStep3",
        //     },
        //   ],
        //   route: `/games/crash/Aviator`,
        //   termsAndCondition: "aviatorTermsCondition",
        // },
        {
          id: 2,
          description: "freeBacaRidesDescription",
          img: {
            en: "/img/banners/gifts/taxi-crash-en.webp",
            fr: "/img/banners/gifts/taxi-crash-fr.webp",
          },
          category: "casino",
          game_name: "Taxi Ride",
          game_category: "crash",
          key: "taxi-crash",
          details: "freeBacaRidesDetails",
          steps: [
            {
              step: "freeBacaRidesStep1",
            },
            {
              step: "freeBacaRidesStep2",
            },
            {
              step: "freeBacaRidesStep3",
            },
          ],
          route: `/games/crash/Taxi Ride`,
          termsAndCondition: "freeBacaRidesTermsCondition",
        },
        {
          id: 3,
          description: "freeBetDescription",
          img: {
            en: "/img/banners/gifts/free-bet-en.webp",
            fr: "/img/banners/gifts/free-bet-fr.webp",
          },
          category: "sports",
          key: "freebet",
          details: "freeBetDetails",
          steps: [
            {
              step: "freeBetStep1",
            },
            {
              step: "freeBetStep2",
            },
            {
              step: "freeBetStep3",
            },
          ],
          route: `/free-bet`,
          termsAndCondition: "freeBetTermsCondition",
        },
        {
          id: 4,
          description: "cometCrashDescription",
          img: {
            en: "/img/banners/gifts/fly-comet-en.webp",
            fr: "/img/banners/gifts/fly-comet-fr.webp",
          },
          category: "casino",
          game_name: "Comet",
          key: "comet",
          game_category: "crash",
          details: "cometCrashDetails",
          steps: [
            {
              step: "cometCrashStep1",
            },
            {
              step: "cometCrashStep2",
            },
            {
              step: "cometCrashStep3",
            },
          ],
          route: `/games/crash/Comet`,
          termsAndCondition: "cometCrashTermsCondition",
        },
        {
          id: 5,
          description: "cashBackPromotionDescription",
          img: {
            en: "/img/banners/gifts/10-cashback-en.webp",
            fr: "/img/banners/gifts/10-cashback-fr.webp",
          },
          category: "sports",
          key: "cashback",
          details: "cashBackPromotionDetails",
          steps: [
            {
              step: "cashBackPromotionStep1",
            },
            {
              step: "cashBackPromotionStep2",
            },
            {
              step: "cashBackPromotionStep3",
            },
          ],
          route: `/deposit`,
          termsAndCondition: "cashBackPromotionTermsCondition",
        },
        {
          id: 6,
          description: "cashOutDescription",
          img: {
            en: "/img/banners/cashout-en.webp",
            fr: "/img/banners/cashout-fr.webp",
          },
          category: "sports",
          key: "cashOut",
          details: "cashOutDetails",
          steps: [
            {
              step: "cashOutStep1",
            },
            {
              step: "cashOutStep2",
            },
            {
              step: "cashOutStep3",
            },
          ],
          route: `/deposit`,
          termsAndCondition: "cashOutTermsCondition",
        },
      ],
    };
  },
  computed: {
    // promotions() {
    //   return this.$store.state.promotions;
    // },
    promotion() {
      const filterPromo = this.promotions.filter(
        (item) => item.id === +this.$route.params.id
      );
      return filterPromo[0];
    },
    profile: function () {
      return this.myProfile;
    },
    isLoggedIn: function () {
      var p = this.getProfile();
      if (!p) {
        return false;
      }
      return true;
    },
    currentLanguage() {
      return this.$i18n.locale;
    },
  },
  methods: {
    closePromotion() {
      this.$router.push(`/gifts`);
    },
    getImageSrc(image) {
      return image[this.currentLanguage] || image.fr;
    },
    routeText(text) {
      return text.replace(
        /\[link\](.*?)\[\/link\]/g,
        `<router-link to="/join">$1</router-link>`
      );
    },
    formatText(text) {
      return text && text.replace(/\/n/g, "<br>");
    },
    handleRegister() {
      this.$router.push(this.isLoggedIn ? this.promotion.route : "/join");
    },
    handlePlayGame() {
      if (this.promotion.route) {
        this.$router.push(this.promotion.route);
        return;
      }
    },
  },
  mounted: function () {
    this.myProfile = this.getProfile();
    window.scrollTo(0, 0);
    // const filterPromo = this.promotions.filter(
    //   (item) => item.id === +this.$route.params.id
    // );
    console.log(
      "promotionspromotionspromotionspromotionspromotions",
      this.currentLanguage
    );
  },
  watch: {
    currentLanguage() {
      console.log(this.$store.state.promotions);
    },
  },
};
</script>

<style scoped src="./index.css"></style>
