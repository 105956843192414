import { render, staticRenderFns } from "./GiftDetail.vue?vue&type=template&id=8c9e41a8&scoped=true"
import script from "./GiftDetail.vue?vue&type=script&lang=js"
export * from "./GiftDetail.vue?vue&type=script&lang=js"
import style0 from "./index.css?vue&type=style&index=0&id=8c9e41a8&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c9e41a8",
  null
  
)

export default component.exports