<template>
  <div>
    <div v-if="isSuccess" class="page_container">
      <div class="success_wrap">
        <div>
          <SuccessCard
            :title="$t('betPlacedSuccessfully')"
            :description="$t('yourBetPlacedSuccessfully')"
          />

          <ChopbetButton @click="handleViewDetails" variant="primary">
            {{ $t("viewBetDetails") }}
          </ChopbetButton>

          <ChopbetButton
            class="share_bet"
            variant="outline"
            @click="handleShare"
            >{{ $t("shareBet") }}</ChopbetButton
          >
          <div class="new_bet">
            <div class="heading">
              <p>{{ $t("placeNewBet") }}</p>
            </div>
            <div class="bet_option" @click="handleNewJackpot('new')">
              <RadioButton v-if="selectedOption !== 'new'" />
              <RadioChecked v-else />
              <div class="text">
                <p>{{ $t("createNewBet") }}</p>
                <p>{{ $t("makeBetSelectionsFromScratch") }}</p>
              </div>
            </div>
            <div class="bet_option" @click="handleNewJackpot('existing')">
              <RadioButton v-if="selectedOption !== 'existing'" />
              <RadioChecked v-else />
              <div class="text">
                <p>{{ $t("createExistingBet") }}</p>
                <p>{{ $t("usePreviousBetSelections") }}</p>
              </div>
            </div>
            <div class="bet_btn">
              <ChopbetButton
                variant="outline"
                @click="handleCloseSuccess"
                :size="'small'"
              >
                {{ $t("continue") }}
              </ChopbetButton>
            </div>
          </div>
        </div>
        <ChopbetSlideUp
          :isOpen="isSlideUpOpen"
          @closeSlideup="closeSlideUp"
          :modal="true"
        >
          <div class="share_modal">
            <div class="cancel">
              <button @click="closeSlideUp"><CancelIcon /></button>
            </div>
            <div class="socials_wrap">
              <p>{{ $t("shareYourBetslip") }}</p>
              <p class="des">
                {{ $t("shareOnAnySocial") }}
              </p>

              <div class="socials">
                <button @click="copyShare">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                  >
                    <path
                      d="M7.93691 10.6C6.73209 9.39041 6.84089 7.31997 8.17931 5.97675L12.0578 2.08389C13.3962 0.739863 15.4578 0.631061 16.6635 1.83988C17.8691 3.0487 17.7595 5.11994 16.421 6.46396L14.4818 8.41039"
                      stroke="var(--text-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M11.0632 7.40039C12.268 8.61001 12.1592 10.6804 10.8208 12.0237L8.88155 13.9701L6.94232 15.9165C5.6039 17.2606 3.54226 17.3694 2.33664 16.1605C1.13102 14.9517 1.24063 12.8805 2.57905 11.5365L4.51828 9.59003"
                      stroke="var(--text-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </button>
                <button @click="shareOnFacebook">
                  <Facebook />
                </button>
                <button @click="shareOnWhatsApp">
                  <Whatsapp />
                </button>
                <button @click="shareOnTelegram">
                  <Telegram />
                </button>
              </div>
            </div>
            <div class="booking_code">
              <p>{{ $t("bookingCode") }}</p>
              <div>
                <p>{{ booking_code }}</p>
                <button @click="copyToClipboard" class="clipboard">
                  <CopyIcon />
                </button>
              </div>
            </div>
          </div>
        </ChopbetSlideUp>
      </div>
    </div>
    <div v-else>
      <div class="page_container">
        <BackComponent goto="/" :title="$t('betSlips')" />
      </div>
      <div class="slip_wrap">
        <div v-if="!hasAtLeastOneFieldInPicks">
          <Booking :hasAtLeastOneFieldInPicks="hasAtLeastOneFieldInPicks" />
        </div>
        <div v-else class="page_container slips">
          <Pick
            v-for="(pick, index) in betslip.picks"
            :key="index"
            v-bind:selection="pick"
          ></Pick>

          <div class="checkes">
            <div>
              <p>
                <span @click="handleAcceptOdds" style="cursor: pointer">
                  <button class="check">
                    <CheckBoxIcon :active="acceptOdds" />
                  </button>
                  {{ $t("acceptOddsChanges") }}
                </span>
                <InfoIcon />
              </p>

              <button @click="removeAll" class="remove">
                <span>{{ $t("removeAll") }}</span>
                <DeleteIcon />
              </button>
            </div>
            <div
              v-if="
                myBonusBalance !== null && isLoggedIn && myBonusBalance != 0
              "
            >
              <p>
                <span
                  @click="handleUseBonus"
                  style="cursor: pointer; text-transform: capitalize"
                >
                  <button class="check">
                    <CheckBoxIcon :active="use_bonus" />
                  </button>
                  {{ $t("useBonus") }}
                </span>
              </p>

              <button @click="removeAll" class="remove">
                <span> {{ myBonusBalance }} {{ currency }} </span>
              </button>
            </div>
          </div>
          <div class="stake_amount">
            <div class="input_wrapper">
              <input
                type="text"
                :placeholder="`Enter stake amount (Min. stake 100${currency})`"
                aria-label="Enter Amount"
                name="amount"
                v-model="amount"
                @input="validateInput"
                maxlength="10"
              />
            </div>
            <div class="auto_add" v-if="!use_bonus">
              <button @click="setAmount(100)">+100</button>
              <button @click="setAmount(500)">+500</button>
              <button @click="setAmount(1000)">
                +{{ comaFormated("1000 ", lang) }}
              </button>
              <button @click="setAmount(2000)">
                +{{ comaFormated("2000 ", lang) }}
              </button>
              <button @click="setAmount(5000)">
                +{{ comaFormated("5000 ", lang) }}
              </button>
              <button @click="setAmount(10000)">
                +{{ comaFormated("10000 ", lang) }}
              </button>
            </div>
          </div>
          <div class="total_summary">
            <div>
              <p class="bold">{{ $t("totalOdds") }}:</p>
              <span>
                <strong>{{ Number(betslip.odds).toFixed(2) }}</strong>
              </span>
            </div>
            <div>
              <p class="bold">{{ $t("totalStake") }}:</p>
              <span
                ><span class="light">{{ currency }}</span
                >{{ " " }}
                <span class="bold">
                  {{ comaFormated(formatToTwoDecimals(amount), lang) }}
                </span>
              </span>
            </div>
            <div>
              <p>
                <span class="bold">{{ $t("possibleWin") }}:</span>
                <InfoIcon />
              </p>
              <span
                ><span class="light">{{ currency }}</span
                >{{ " " }}
                <span class="bold">
                  {{
                    comaFormated(
                      formatToTwoDecimals(betslip.odds * amount),
                      lang
                    )
                  }}
                </span>
              </span>
            </div>
          </div>
          <ChopbetButton
            v-show="!hasSuspendedPicks"
            variant="primary"
            :disabled="disabled"
            @click="handlePlaceBetClick"
            :loading="loading"
          >
            {{ $t("placeBet") }}</ChopbetButton
          >
          <ChopbetButton
            v-show="hasSuspendedPicks"
            variant="primary"
            @click="removeSuspendedPicks"
          >
            Remove Suspended Selections
          </ChopbetButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackComponent from "../../components/ui/BackComponent.vue";
import ChopbetButton from "../../components/ui/ChopbetButton.vue";
import DeleteIcon from "../../components/icons/DeleteIcon.vue";
import Pick from "./components/Pick.vue";
import InfoIcon from "../../components/icons/InfoIcon.vue";
import CheckBoxIcon from "../../components/icons/CheckBoxIcon.vue";
import { pushToDataLayer } from "../../utils/gtm";
import Booking from "./components/Booking.vue";
import SuccessCard from "../../components/ui/SuccessCard.vue";
import ChopbetSlideUp from "../../components/ui/ChopbetSlideUp.vue";
import bettingserve from "../../services/bettingserve";
import CancelIcon from "@/components/icons/CancelIcon.vue";
import { comaFormated } from "../../utils/formatter";
import bonusserve from "@/services/bonus";
import RadioButton from "../../components/icons/RadioButton.vue";
import RadioChecked from "../../components/icons/RadioChecked.vue";
import CopyIcon from "@/components/icons/CopyIcon.vue";
import Facebook from "@/components/icons/Facebook.vue";
import Whatsapp from "@/components/icons/Whatsapp.vue";
import Telegram from "@/components/icons/Telegram.vue";

export default {
  name: "BetSlip",
  components: {
    BackComponent,
    Booking,
    CheckBoxIcon,
    InfoIcon,
    Pick,
    ChopbetButton,
    SuccessCard,
    ChopbetSlideUp,
    DeleteIcon,
    RadioChecked,
    RadioButton,
    CancelIcon,
    CopyIcon,
    Facebook,
    Whatsapp,
    Telegram,
  },
  data() {
    return {
      currency: process.env.VUE_APP_CURRENCY || "USD",
      amount: 0,
      use_bonus: false,
      acceptOdds: true,
      useFreeBets: false,
      loading: false,
      isSuccess: false,
      isError: "",
      code: "",
      msg: "",
      share_odds: "",
      copyText: "Copy",
      copyBookingCode: "Click to copy",
      myProfile: this.getProfile(),
      accept_odds_changes: true,
      previous_odds: 0,
      odds_changed: false,
      seen: false,
      withholding_tax: 0,
      booking_code: "",
      shareLink: "",
      hasSuspendedPicks: false,
      isSlideUpOpen: false,
      clientUrl: window.location.origin,
      lang: this.$i18n.locale,
      suspendedGames: [],
      myBonusBalance: null,
      selectedOption: "new",
    };
  },
  computed: {
    isLoggedIn: function () {
      var p = this.getProfile();
      if (!p) {
        return false;
      }
      return true;
    },
    isDevelopment() {
      return process.env.VUE_APP_ENVIRONMENT === "development";
    },
    betslip() {
      return this.$store.state.betslip || { picks: {} };
    },
    hasAtLeastOneFieldInPicks() {
      if (this.betslip.picks) {
        const res = Object.keys(this.betslip.picks).some((key) => {
          const pick = this.betslip.picks[key];
          return Object.keys(pick).length > 0;
        });
        return res;
      }
      return false;
    },

    disabled() {
      return !this.acceptOdds || this.amount <= 0;
    },
  },
  watch: {
    acceptOdds() {
      // this.updateDisabledState();
    },
    amount() {
      // this.updateDisabledState();
    },
    isSlideUpOpen(newVal) {
      if (newVal) {
        this.isSlideUpOpen = true;
      }
    },
  },
  methods: {
    comaFormated,
    handleNewJackpot(selectedOption) {
      this.selectedOption = selectedOption;
    },
    handleSuccess() {
      this.isSuccess = true;
      this.selectedOption = "new";
    },
    handleCloseSuccess() {
      this.isSuccess = false;
      if (this.selectedOption === "new") {
        this.$router.push("/");
        this.removeAllPicks();
      }
    },
    closeSlideUp() {
      this.isSlideUpOpen = false;
    },
    handleShare() {
      this.isSlideUpOpen = true;
    },
    handleViewDetails() {
      this.$router.push("/my-bets");

      this.removeAllPicks();
    },
    // validateInput(event) {
    //   const value = event.target.value.replace(/[^0-9.]/g, "");
    //   const decimalCheck =
    //     value.split(".").length > 2 ? value.replace(/\.+$/, "") : value;
    //   this.amount = decimalCheck;
    // },

    validateInput(event) {
      const numericAmount = Number(this.amount);
      if (this.use_bonus) {
        if (numericAmount > this.myBonusBalance) {
          this.amount = this.myBonusBalance.toString();
        }
      } else {
        const value = event.target.value.replace(/[^0-9.]/g, "");
        const decimalCheck =
          value.split(".").length > 2 ? value.replace(/\.+$/, "") : value;
        this.amount = decimalCheck;
      }
    },
    formatToTwoDecimals(value) {
      return Math.floor(value * 100) / 100;
    },
    totalStake() {
      return this.amount * this.betslip.odds || 0;
    },
    openLinkInNewTab(link) {
      window.open(link, "_blank");
    },

    hideBetslip: function () {
      document.getElementById("faso-close").click();
    },
    setAmount(amount) {
      const stake = Number(parseFloat(amount).toFixed(2));
      const bt = this.getObject("bslip");
      bt.stake = stake;
      this.saveObject("bslip", bt);
      this.amount = stake;
    },

    handleAcceptOdds() {
      this.acceptOdds = !this.acceptOdds;
    },
    handleUseBonus() {
      if (this.use_bonus) {
        console.log(this.use_bonus, "this.use_bonusthis.use_bonus");
        this.setAmount(0);
      } else {
        this.setAmount(this.myBonusBalance);
      }
      this.use_bonus = !this.use_bonus;
    },
    handleUseFreeBets() {
      this.useFreeBets = !this.useFreeBets;
    },
    handlePlaceBetClick(event) {
      if (this.loading) {
        return;
      }
      this.placeBet(event);
    },
    trackPlaceBetSuccessfulButtonClick(event) {
      pushToDataLayer("gtm.betPlacedSuccessful", {
        category: "Button",
        action: "Click",
        label: "betPlacedSuccessful",
        element: event.target,
      });
    },
    trackPlaceBetUnsuccessfulButtonClick(event) {
      pushToDataLayer("gtm.BetPlaceUnsuccessful", {
        category: "Button",
        action: "Click",
        label: "BetPlaceUnsuccessful",
        element: event.target,
      });
    },
    removeAll() {
      this.$router.push("/bet-slip");
      this.removeAllPicks();
    },
    placeBet: async function (event) {
      this.reset();
      var p = this.getProfile();
      if (!p) {
        this.setError(this.$t("pleaseLoginProceed"));

        this.setValue("placeBet", 1);
        this.$router.push({ name: "login", params: {} });
        return;
      }
      var bet_amount = this.amount;
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      if (bet_amount < 10) {
        this.setError("Please enter bet amount greater of 10 CFA. or more");
        return;
      }
      var betslipData = this.betslip;
      if (betslipData.total === 0) {
        this.setError("Please Select at least one outcome to continue");
        return;
      }
      if (!this.accept_odds_changes && this.odds_changed) {
        this.setError(`${this.$t("acceptOddsChangesProceed")}`);

        return;
      }
      var bets = [];
      this.jQuery.each(betslipData.picks, function (k, v) {
        bets.push({
          market_id: parseInt(v.market_id),
          match_id: parseInt(v.match_id),
          outcome_id: v.outcome_id,
          producer_id: v.producer_id,
          specifier: v.specifier,
        });
      });
      var data = {
        bet_type: parseInt(1),
        bets: bets,
        booking_code: "",
        campaign: String(utm_campaign),
        ip_address: "",
        medium: String(utm_medium),
        source: this.isMobile() ? 2 : 1,
        stake: parseInt(bet_amount),
        stake_type: this.use_bonus ? 2 : 1,
        utm_source: String(utm_source),
        referrer: document.referrer,
      };
      this.loading = true;
      var vm = this;
      var path = `/bet?lang=${this.$i18n.locale}`;

      try {
        vm.loading = true;
        const res = await bettingserve.post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        });

        vm.loading = false;
        vm.bet_id = res.data.bet_id;
        vm.booking_code = res.data.bet_id;
        this.$store.dispatch("setShowSlip", false);
        this.isSuccess = true;
        this.removeAllPicks("rebet");
        this.$router.push({ name: "/bet-slip" });

        vm.trackPlaceBetSuccessfulButtonClick(event);
        vm.removeObject("booking_code");
        vm.removeObject("utm_source");
      } catch (err) {
        vm.loading = false;
        let message = "";
        if (err.response) {
          message = err.response.data.error_message || message;
          const status = parseInt(err.response.status);
          if (status === 422) {
            this.setError(message);
            // this.hasSuspendedPicks = true;
            const suspendedMatch = err.response.data.data;
            const numbersArray = suspendedMatch
              .substring(1, suspendedMatch.length - 1)
              .split(" ")
              .map(Number);
            numbersArray.forEach((match) => {
              this.suspendedGames.push(this.betslip.picks[match]);
              this.betslip.picks[match].status = -1;
            });
            return;
          }
          if (status === 428) {
            this.setError(message);
            return;
          }
          if (status === 401) {
            this.setError(message);
            vm.logout();
            return;
          }
          this.setError(message);
        }
      }
    },
    removeSuspendedPicks: function () {
      this.hasSuspendedPicks = false;
      let bSlip = this.getObject("bslip") || [];
      bSlip = bSlip.filter((v) => {
        const isSuspended = this.suspendedGames.some(
          (pick) => v && v.match_id === pick.match_id
        );
        if (isSuspended) this.hasSuspendedPicks = true;
        return !isSuspended;
      });
      this.saveObject("bslip", bSlip);
      this.suspendedGames = [];
    },
    shareOnFacebook() {
      const code = this.booking_code;
      this.closeSlideUp();
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/bet-slip/${code}`;
      window.open(facebookUrl, "_blank");
    },
    shareOnTelegram() {
      const code = this.booking_code;
      this.closeSlideUp();
      const telegramWebUrl = `https://t.me/share/url?url=${encodeURIComponent(
        window.location.origin + "/bet-slip/" + code
      )}`;
      window.open(telegramWebUrl, "_blank");
    },
    shareOnWhatsApp() {
      const code = this.booking_code;
      this.closeSlideUp();
      const whatsappUrl = `https://api.whatsapp.com/send?text=${window.location.origin}/bet-slip/${code}`;
      window.open(whatsappUrl, "_blank");
    },
    async copyShare() {
      try {
        const code = this.booking_code;
        const fullUrl = `${window.location.origin}/bet-slip/${code}`;
        await navigator.clipboard.writeText(fullUrl);
        this.setSuccess("Share link copied to clipboard!");
        this.closeSlideUp();
      } catch (err) {
        this.closeSlideUp();
        this.setError("Failed to copy text!");
      }
    },
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.booking_code);
        this.setSuccess("Text copied to clipboard!");
        this.closeSlideUp();
      } catch (err) {
        this.closeSlideUp();
        this.setError("Failed to copy text!");
      }
    },
    fetchMyBonusBalance() {
      var vm = this;
      const path = process.env.VUE_APP_BONUS_BASE_URL + "/bonus/balance";
      bonusserve
        .get(path, {
          headers: {
            "Content-Type": "application/json",
            "api-key": vm.getAuth(),
          },
        })
        .then((response) => {
          this.myBonusBalance = response.data.balance;
        })
        .catch((err) => {
          console.error("Error bonus balance:", err);
        });
    },
  },
  mounted() {
    var vm = this;
    this.EventBus.$on("event:betslip:odds-deactivated", function () {
      vm.hasSuspendedPicks = true;
    });
    this.fetchMyBonusBalance();
    const params = this.$route.params.id;
    if (params) {
      this.removeAllPicks();
    }
  },
};
</script>

<style scoped>
.cancel {
  text-align: right;
  padding-right: 16px;
  padding-top: 16px;
}
p {
  margin: 0;
}

.cancel button {
  border: none;
  color: var(--text-color);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.16px;
  background-color: inherit;
}
.socials_wrap {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  padding: 24px 0;
  max-width: 220px;
}

.socials_wrap p {
  color: var(--text-color);
  text-align: center;
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.16px;
}

.socials_wrap .des {
  color: var(--sub-text-color);
  font-size: 12px;
}

.socials {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.socials button {
  border: none;
  cursor: pointer;
  background-color: var(--background-color);
  display: flex;
  width: 40px;
  height: 40px;
  /* padding: 12px; */
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #cc1717;
}

.socials button svg {
  margin-top: -5px;
  margin-left: -2px;
}
.booking_code {
  padding: 0 15px;
  margin-bottom: 3rem;
}

.booking_code p {
  color: var(--text-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.12px;
  margin: 0;
  margin-bottom: 2px;
}

.booking_code > div {
  display: flex;
  height: 40px;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: var(--background-color);
  margin-bottom: 0.7rem;
}

.booking_code > div p {
  margin: 0;
}

.betslips {
  border-bottom: 1px solid var(--border);
  padding: 8px;
  gap: 4px;
}

.slip_wrap::-webkit-scrollbar {
  display: none;
}

.betslips > div {
  justify-content: space-between;
  display: flex;
  align-items: center;
  align-items: flex-start;
}

.betslips > div p {
  margin: 0;
  color: var(--text-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
  width: 70%;
}

span {
  color: var(--sub-text-color);
  font-family: "Satoshi";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.12px;
}

.total_summary {
  margin-top: 16px;
  margin-bottom: 16px;
}

.total_summary div {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px;
  align-items: center;
}

.total_summary div p {
  margin: 0;
  margin-bottom: 12px;
}

.bold {
  color: var(--text-color);
  font-family: "Satoshi";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
}

.light {
  color: var(--sub-text-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.12px;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.actions button:nth-child(1) {
  width: 25%;
}

.actions button:nth-child(2) {
  width: 71%;
}

.auto_add {
  display: flex;
  justify-content: space-between;
  display: flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}

.auto_add::-webkit-scrollbar {
  display: none;
}

.auto_add button {
  padding: 6px 12px 6px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 70px;
  border: 1px solid var(--border);
  background-color: var(--background-color);
  color: var(--text-color);
  margin-right: 4px;
  cursor: pointer;
  min-width: 75px;
}

.auto_add button:hover {
  background-color: var(--box-bg);
}

.checkes {
  margin-top: 16px;
}

.checkes div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: flex-start;
  margin-bottom: 14px;
  gap: 0.5rem;
}

.checkes div p {
  margin: 0;
  width: 60%;
  word-break: keep-all;
  word-wrap: break-word;
}
.checkes div p span {
  word-break: keep-all;
  word-wrap: break-word;
  white-space: normal;
}

svg {
  margin-bottom: -3px;
  margin-left: 4px;
}

.check {
  outline: none;
  border: none;
  padding: 0;
  background-color: var(--background-color);
  margin-right: 8px;
}

.delete {
  width: 16px;
  height: 20px;
  margin: -3px;
  cursor: pointer;
}

.remove {
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
}

.wrap {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrap div {
  width: 100%;
}

.error {
  background-color: red;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 3px;
}

.error span {
  color: var(--text-color);
  font-size: 12px;
  font-weight: 700;
}

.slips {
  padding-bottom: 4rem;
}

.share {
  margin-top: 8px;
  padding-bottom: 3rem;
}

.clipboard {
  background-color: inherit;
  border: none;
}

.share_modal {
  padding-bottom: 5rem;
}

.success_wrap {
  padding-top: 70px;
}

.success_wrap {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    width: 100%;
  }
}

.new_bet {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
  border-radius: 8px;
  background-color: var(--bg-screen);
}

.bet_btn {
  margin-top: 8px;
}

.heading p {
  font-size: 14px;
  font-weight: 700;
  color: var(--text-color);
}

.bet_option {
  background-color: var(--base-light-100);
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  gap: 8px;
  cursor: pointer;
}
.bet_option:nth-child(1) {
  width: unset;
}

.bet_option .text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.bet_option .text p {
  font-size: 12px;
}

.bet_option .text p:nth-child(1) {
  font-weight: 700;
  color: var(--text-color);
}
.bet_option .text p:nth-child(2) {
  color: var(--sub-text-color);
}
.share_bet {
  margin-top: 8px;
}
</style>
