<template>
  <div class="slider-container hero-container">
    <div class="image-slider">
      <transition :name="transitionName">
        <img
          @click="handleBanner(currentImage)"
          v-if="currentImage.src"
          :src="currentImage.src"
          :key="currentImage.src"
          class="slider-image"
          alt="Sliding Image"
        />
      </transition>
      <div @click="prevImage" class="carousel_arrow left_arrow">
        <BannerArrow />
      </div>
      <div class="carousel_arrow right_arrow" @click="nextImage">
        <BannerArrow />
      </div>
    </div>
    <div class="text_wrap">
      <img :src="bannerShape" alt="" />
      <div class="text_slide_wrap">
        <div class="slider-text" v-for="text in texts" :key="text">
          <p>{{ currentText }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BannerArrow from "../icons/BannerArrow.vue";

export default {
  data() {
    return {
      language: this.$i18n.locale,
      currentImageIndex: 0,
      transitionName: "slide-right",
      texts: [
        `${this.$t("textPlayEverywhere")}`,
        `${this.$t("feelThrill")}`,
        `${this.$t("scoreBigWinBig")}`,
        `${this.$t("unleashThePowerOfYourLuck")}`,
      ],
      currentTextIndex: 0,
      textTransitionName: "slide-left",
      bannerShape: "/img/bannerShape.png",
      disabledCtr: false,
    };
  },
  components: {
    BannerArrow,
  },
  computed: {
    isLoggedIn() {
      const profile = this.getProfile();
      return !!profile;
    },
    filteredBanner() {
      const images =
        this.currentLanguage === "fr" ? this.imagesFr : this.imagesEn;
      return images.filter((item) =>
        this.isLoggedIn ? item : !item.isLoggedIn
      );
    },
    currentImage() {
      return this.filteredBanner[this.currentImageIndex];
    },
    currentText() {
      return this.texts[this.currentTextIndex];
    },
    currentLanguage() {
      return this.$i18n.locale;
    },
  },
  watch: {
    currentLanguage() {
      // this.currentImageIndex = 0;
      this.startSlider();
    },
  },
  mounted() {
    const images = this.language === "fr" ? this.imagesFr : this.imagesEn;
    this.startSlider();

    return images.filter((item) => (this.isLoggedIn ? item : !item.isLoggedIn));
  },
  methods: {
    startSlider() {
      this.slideInterval = setInterval(() => {
        this.nextImage();
      }, 3000);
    },
    restartSlider() {
      clearInterval(this.slideInterval);
      setTimeout(this.startSlider, 1000);
    },
    nextImage() {
      if (this.disabledCtr) return;
      this.disabledCtr = true;
      this.restartSlider();
      this.transitionName = "slide-right";
      this.currentImageIndex =
        (this.currentImageIndex + 1) % this.filteredBanner.length;
      this.currentTextIndex = (this.currentTextIndex + 1) % this.texts.length;

      setTimeout(() => {
        this.disabledCtr = false;
      }, 1000);
    },
    prevImage() {
      if (this.disabledCtr) return;
      this.disabledCtr = true;
      this.restartSlider();
      this.transitionName = "slide-left";
      this.currentImageIndex =
        (this.currentImageIndex - 1 + this.filteredBanner.length) %
        this.filteredBanner.length;
      this.currentTextIndex =
        (this.currentTextIndex - 1 + this.texts.length) % this.texts.length;

      // Reset disabledCtr after 1000ms
      setTimeout(() => {
        this.disabledCtr = false;
      }, 1000);
    },
    handleBanner(currentImage) {
      // route
      if (currentImage.route) {
        this.$store.dispatch("triggerCasinoSlide", currentImage);
        this.$router.push(currentImage.route);
        return;
      }

      if (currentImage.label === "games") {
        this.$router.push("/games");
      }
      if (currentImage.label === "jackpot") {
        this.$router.push("/jackpot");
      }
      if (currentImage.label === "telegram") {
        window.location.href = "https://t.me/choplifeci";
      }
      if (currentImage.label === "faq") {
        this.$router.push("/faq");
      }
      if (currentImage.label === "cashback") {
        this.$router.push("/gifts/5");
      }
      if (currentImage.label === "cashout") {
        this.$router.push("/gifts/6");
      }
      if (currentImage.label === "aviator") {
        this.$router.push("/gifts/7");
      }
      if (!currentImage.label) {
        if (this.isLoggedIn) {
          this.$router.push("/deposit");
        } else {
          this.$router.push("/login");
        }
      }
      if (currentImage.label === "boosted") {
        this.$store.dispatch("setLeagueFilter", false);
        this.$store.dispatch("setFixtureFilter", 2);
      }
      // if (currentImage.label === "login") {
      //   if (this.isLoggedIn) {
      //     return this.$router.push("/deposit");
      //   }
      //   this.$router.push("/login");
      // }
    },
  },
  beforeDestroy() {
    clearInterval(this.slideInterval);
  },
  props: {
    openSlideUp: {
      required: false,
    },
    imagesFr: {
      default: [
        {
          src: "/img/banners/100-fr.webp",
          isLoggedIn: false,
        },
        {
          src: "/img/banners/500-fr.webp",
          isLoggedIn: false,
          label: "jackpot",
        },
        // {
        //   src: "/img/banners/aviator-fr.webp",
        //   isLoggedIn: false,
        //   label: "aviator",
        // },
        {
          src: "/img/banners/cashout-fr.webp",
          isLoggedIn: false,
          label: "cashout",
        },
        {
          src: "/img/banners/boosted-fr1.webp",
          isLoggedIn: false,
          label: "boosted",
        },
        {
          src: "/img/banners/cashback-fr1.webp",
          isLoggedIn: false,
          label: "cashback",
        },
        {
          src: "/img/banners/chap-fr.webp",
          isLoggedIn: true,
          label: "games",
        },
        {
          src: "/img/banners/ligue-champions-fr.webp",
          isLoggedIn: false,
        },
        {
          src: "/img/banners/telegram-fr.webp",
          isLoggedIn: false,
          label: "telegram",
        },
        {
          src: "/img/banners/faq-fr.webp",
          isLoggedIn: false,
          label: "faq",
        },
      ],
    },
    imagesEn: {
      default: [
        {
          src: "/img/banners/100-en.webp",
          isLoggedIn: false,
        },
        {
          src: "/img/banners/500-en.webp",
          isLoggedIn: false,
          label: "jackpot",
        },
        // {
        //   src: "/img/banners/aviator-en.webp",
        //   isLoggedIn: false,
        //   label: "aviator",
        // },
        {
          src: "/img/banners/cashout-fr.webp",
          isLoggedIn: false,
          label: "cashout",
        },
        {
          src: "/img/banners/boosted-en1.webp",
          isLoggedIn: false,
          label: "boosted",
        },
        {
          src: "/img/banners/cashback-en1.webp",
          isLoggedIn: false,
          label: "cashback",
        },
        // {
        //   src: "/img/banners/boosted-en.webp",
        //   isLoggedIn: false,
        //   label: "boosted",
        // },
        {
          src: "/img/banners/chap-en.webp",
          isLoggedIn: true,
          label: "games",
        },
        {
          src: "/img/banners/ligue-champions-en.webp",
          isLoggedIn: false,
        },
        {
          src: "/img/banners/telegram-en.webp",
          isLoggedIn: false,
          label: "telegram",
        },
        {
          src: "/img/banners/faq-en.webp",
          isLoggedIn: false,
          label: "faq",
        },
      ],
    },
  },
};
</script>

<style scoped>
.left_arrow {
  top: 35px;
  left: 5px;
}
.right_arrow {
  top: 35px;
  right: 5px;
}

.right_arrow svg {
  rotate: 180deg;
}

.carousel_arrow {
  /* background: #444444; */
  cursor: pointer;
  border-radius: 50%;
  padding: 5px;
  position: absolute;
  display: flex;
  justify-content: center;
}
.hero-container {
  background: #cc1717;
  position: relative;
}
.text_wrap {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40%;
  color: white;
  font-size: 20px;
  font-weight: bold;
  z-index: 20;
}
.text_wrap img {
  width: 100%;
  height: 100%;
  margin-left: -10px;
  /* object-fit: cover; */
  /* outline: 1px solid red; */
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  /* background-color: red; */
  /* outline: 1px solid red; */
  /* border-radius: 10px; */
}
.slider-container {
  width: 100%;
  height: 100px;
  overflow: hidden;
  position: relative;
}

.image-slider {
  width: 100%;
  height: 100%;
  position: relative;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  /* outline: 4px solid red; */
  /* background-color: red; */
  /* border-radius: 10px; */
}
/* Slide Left Transition */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 1s ease;
}
.slide-left-enter {
  transform: translateX(100%); /* Starts outside from the right */
}
.slide-left-leave-to {
  transform: translateX(-100%); /* Exits outside to the left */
}

/* Slide Right Transition */
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 1s ease;
}
.slide-right-enter {
  transform: translateX(-100%); /* Starts outside from the left */
}
.slide-right-leave-to {
  transform: translateX(100%); /* Exits outside to the right */
}

/* .slide-right-enter {
  transform: translateX(100%);
}
.slide-right-leave-to {
  transform: translateX(-100%);
} */
.text_slide_wrap {
  /* outline: 1px solid red; */
  position: absolute;
  top: 0;
  right: 0;
  z-index: 30;
  /* height: 50px; */
}

.slider-text {
  min-width: 100px;
  height: 100%;
  /* margin-right: 0;
  margin-left: auto; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
p {
  font-size: 12px;
  max-width: 90%;
  text-align: right;
  font-family: "Game Station";
  font-weight: 400;
  padding-left: 20px;
}
</style>
