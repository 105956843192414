<template>
  <div class="promo-wrapper">
    <div v-if="!selectedPromotion">
      <div class="select-sport">
        <button
          :class="{ active: activeCategory === 'all' }"
          @click="setCategory('all')"
        >
          {{ $t("all") }}
        </button>
        <button
          :class="{ active: activeCategory === 'sports' }"
          @click="setCategory('sports')"
        >
          {{ $t("sports") }}
        </button>
        <!-- v-if="isLoggedIn" -->
        <button
          :class="{ active: activeCategory === 'casino' }"
          @click="setCategory('casino')"
        >
          {{ $t("games") }}
        </button>
      </div>
      <div style="background-color: var(--background-color)" class="promotions">
        <!-- <div v-if="activeCategory === 'casino' && !isLoggedIn ">
          <NoData
            :title="$t('logIntoYourAccount')"
            :description="$t('pleaseLoginProceed')"
            :cta="$t('login')"
            noDataTo="/login"
          />
        </div> -->
        <div
          v-for="(promotion, index) in filteredMenuLinks"
          :key="index"
          class="card"
        >
          <div class="card-top">
            <img :src="getImageSrc(promotion.img)" alt="Promotion Image" />
          </div>
          <div class="card-center">
            <span>{{ $t(promotion.category) }}</span>
          </div>
          <div class="content">
            <p class="first-deposit">{{ $t(promotion.description) }}</p>
            <span>{{ $t(promotion.details) }}</span>
            <div class="btn">
              <button class="outline" @click="selectPromotion(promotion)">
                {{ $t("learnMore") }}
              </button>
              <button @click="goToJackpot(promotion)">
                {{ $t("betNow") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bettingserve from "../../services/bettingserve";
// import NoData from "@/components/ui/NoData.vue";

export default {
  name: "Gifts",

  data() {
    return {
      isDev: process.env.VUE_APP_ENVIRONMENT === "development",
      activeCategory: "all",
      activeDetail: false,
      selectedPromotion: null,
      balance: 0,
      promotions: [
        {
          id: 1,
          description: "100description",
          img: {
            en: "/img/banners/100-en.webp",
            fr: "/img/banners/100-fr.webp",
          },
          category: "sports",
          key: "100%",
          details: "100Steps",
          steps: [
            {
              step: "step1",
            },
            {
              step: "100Steps2",
            },
            {
              step: "100Steps3",
            },
          ],
          route: `/deposit`,
          termsAndCondition: "100TermsCondition",
        },
        {
          id: 2,
          description: "freeBacaRidesDescription",
          img: {
            en: "/img/banners/gifts/taxi-crash-en.webp",
            fr: "/img/banners/gifts/taxi-crash-fr.webp",
          },
          category: "casino",
          game_name: "Taxi Ride",
          game_category: "crash",
          key: "taxi-crash",
          details: "freeBacaRidesDetails",
          steps: [
            {
              step: "freeBacaRidesStep1",
            },
            {
              step: "freeBacaRidesStep2",
            },
            {
              step: "freeBacaRidesStep3",
            },
          ],
          route: `/games/crash/Taxi Ride`,
          termsAndCondition: "freeBacaRidesTermsCondition",
        },
        {
          id: 3,
          description: "freeBetDescription",
          img: {
            en: "/img/banners/gifts/free-bet-en.webp",
            fr: "/img/banners/gifts/free-bet-fr.webp",
          },
          category: "sports",
          key: "freebet",
          details: "freeBetDetails",
          steps: [
            {
              step: "freeBetStep1",
            },
            {
              step: "freeBetStep2",
            },
            {
              step: "freeBetStep3",
            },
          ],
          route: `/free-bet`,
          termsAndCondition: "freeBetTermsCondition",
        },
        {
          id: 4,
          description: "cometCrashDescription",
          img: {
            en: "/img/banners/gifts/fly-comet-en.webp",
            fr: "/img/banners/gifts/fly-comet-fr.webp",
          },
          category: "casino",
          game_name: "Comet",
          key: "comet",
          game_category: "crash",
          details: "cometCrashDetails",
          steps: [
            {
              step: "cometCrashStep1",
            },
            {
              step: "cometCrashStep2",
            },
            {
              step: "cometCrashStep3",
            },
          ],
          route: `/games/crash/Comet`,
          termsAndCondition: "cometCrashTermsCondition",
        },
        {
          id: 5,
          description: "cashBackPromotionDescription",
          img: {
            en: "/img/banners/gifts/10-cashback-en.webp",
            fr: "/img/banners/gifts/10-cashback-fr.webp",
          },
          category: "sports",
          key: "cashback",
          details: "cashBackPromotionDetails",
          steps: [
            {
              step: "cashBackPromotionStep1",
            },
            {
              step: "cashBackPromotionStep2",
            },
            {
              step: "cashBackPromotionStep3",
            },
          ],
          route: `/deposit`,
          termsAndCondition: "cashBackPromotionTermsCondition",
        },
        {
          id: 6,
          description: "cashOutDescription",
          img: {
            en: "/img/banners/cashout-en.webp",
            fr: "/img/banners/cashout-fr.webp",
          },
          category: "sports",
          key: "cashOut",
          details: "cashOutDetails",
          steps: [
            {
              step: "cashOutStep1",
            },
            {
              step: "cashOutStep2",
            },
            {
              step: "cashOutStep3",
            },
          ],
          route: `/deposit`,
          termsAndCondition: "cashOutTermsCondition",
        },
        // {
        //   id: 7,
        //   description: "aviatorDescription",
        //   img: {
        //     en: "/img/banners/aviator-en.webp",
        //     fr: "/img/banners/aviator-fr.webp",
        //   },
        //   category: "casino",
        //   key: "aviator",
        //   details: "aviatorStep",
        //   steps: [
        //     {
        //       step: "aviatorStep1",
        //     },
        //     {
        //       step: "aviatorStep2",
        //     },
        //     {
        //       step: "aviatorStep3",
        //     },
        //   ],
        //   route: `/games/crash/Aviator`,
        //   termsAndCondition: "aviatorTermsCondition",
        // },
      ],
    };
  },
  components: {
    // NoData,
  },
  computed: {
    // promotions() {
    //   const items = this.$store.state.promotions;
    //   return items;
    // },
    balanceText() {
      return this.balance === 0 ? 0 : 1;
    },
    currentLanguage() {
      return this.$i18n.locale;
    },
    filteredMenuLinks() {
      let filteredLinks =
        this.activeCategory === "all"
          ? this.promotions
          : this.promotions.filter(
              (promotion) => promotion.category === this.activeCategory
            );
      // filteredLinks = this.isLoggedIn
      //   ? filteredLinks
      //   : filteredLinks.filter(
      //       (link) => !this.isLoggedIn && link.category !== "casino"
      //     );

      filteredLinks = filteredLinks.filter((link) =>
        !this.isLoggedIn
          ? link
          : !(link.route === "/free-bet" && this.balance === 0)
      );
      return filteredLinks;
    },
    isLoggedIn: function () {
      var p = this.getProfile();
      if (!p) {
        return false;
      }
      return true;
    },
  },
  methods: {
    getImageSrc(image) {
      return image[this.currentLanguage] || image.fr;
    },
    setCategory(category) {
      this.activeCategory = category;
    },
    selectPromotion(promotion) {
      this.$router.push(`/gifts/${promotion.id}`);
      this.activeDetail = true;
    },
    closePromotion() {
      this.$router.push(`/gifts`);
    },
    goToJackpot(promotion) {
      if (promotion.category === "casino") {
        this.$router.push(promotion.route);
      } else if (promotion.route) {
        this.$router.push(this.isLoggedIn ? promotion.route : "/join");
      } else {
        this.$router.push("/jackpot");
      }
    },
    async getFreeBalance() {
      try {
        const path = `${process.env.VUE_APP_BASE_FREEBET_URL}/balance`;
        const response = await bettingserve.get(path, {
          headers: {
            "api-key": this.getAuth(),
          },
        });
        const todayDate = new Date();
        const expiryDate = new Date(response.data.expiry_date);
        if (
          response.data.balance !== 0 &&
          expiryDate.getTime() >= todayDate.getTime()
        ) {
          this.balance = response.data.balance;
        }
      } catch (error) {
        if (error.response) {
          if ([401, 400, 428].includes(error.response.status)) {
            // this.setError(`${this.$t("sessionExpired")}`);
            // this.logout();
          } else {
            this.setError(error.response.data.message);
          }
        } else {
          // this.setError("An error occurred while fetching data. Please try again later.");
        }
      } finally {
        this.balanceLoading = false;
        this.loading = false;
      }
    },

    handlePlayGame() {
      console.log(this.promotion, "this.promotionthis.promotionthis.promotion");
    },
  },
  mounted: function () {
    this.getFreeBalance();
  },
  watch: {
    currentLanguage() {
      console.log("first", this.promotions);
    },
  },
};
</script>

<style scoped src="./index.css"></style>
