<template>
  <div class="wrapper">
    <div class="page_container">
      <BackComponent :title="$t('customerSupport')" />
    </div>
    <section class="customer-support">
      <div class="link">
        <div class="link_div" @click="initializeCrips">
          <div class="customer-link">
            <img src="/menu/chat.svg" class="side-icon-svg" />
            <div>
              <p>{{ $t("Onlinechat") }}</p>
              <span class="text-dark">{{ $t("sendUsMessage") }}</span>
            </div>
          </div>
          <img src="/menu/right.svg" class="side-icon-svg" />
        </div>
      </div>
      <div class="link" v-for="(link, index) in supportLinks" :key="index">
        <a :href="link.route" @click.native="closeMenu">
          <div class="customer-link">
            <img :src="link.icon" class="side-icon-svg" />
            <div>
              <p>{{ link.title }}</p>
              <span v-if="link.subtitle" class="text-dark">{{
                link.subtitle
              }}</span>
            </div>
          </div>
          <img src="/menu/right.svg" class="side-icon-svg" />
        </a>
      </div>
      <div class="link">
        <div
          class="text-dark accordion link_div"
          :aria-expanded="collapsedSections['1']"
          @click="toggleCollapse('1')"
        >
          <div class="customer-link">
            <img src="/menu/time_icon.png" class="side-icon-svg" />
            <div>
              <p>{{ $t("availabilityTiming") }}</p>
              <span class="text-dark">{{ $t("sendUsMessage") }}</span>
            </div>
          </div>

          <img
            v-if="collapsedSections['1']"
            src="/menu/right.svg"
            class="side-icon-svg open_time"
          />
          <img v-else src="/menu/right.svg" class="side-icon-svg" />
        </div>
        <div id="1" class="customer_content" v-show="collapsedSections['1']">
          <span> Mon: 0800-1700</span>
          <span> Tue: 0800-1900</span>
          <span> Wed: 0800-1900</span>
          <span>Thur: 0800-1900</span>
          <span>Fri: 0800-1900</span>
          <span> Sat: 1000-1900</span>
          <span> Sun: 1000-1900</span>
        </div>
      </div>

      <div class="follow-up">
        <p class="text-dark">{{ $t("followUs") }}</p>
      </div>

      <div
        v-for="(social, index) in socialLinks"
        :key="index"
        class="link social"
      >
        <a :href="social.route" target="_blank" @click.native="closeMenu">
          <div class="customer-link">
            <img :src="social.icon" class="side-icon-svg mr-2" />
            <div>
              <p>{{ social.title }}</p>
            </div>
          </div>
          <img src="/menu/right.svg" class="side-icon-svg" />
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import walletserve from "@/services/walletserve";
import bonusserve from "@/services/bonus";

export default {
  name: "Setting",
  components: {
    // SortDeposit,
    BackComponent: () => import("@/components/ui/BackComponent"),
    // MenuItem,
  },
  data: function () {
    const totalSections = 27;
    const collapsedSections = {};

    for (let i = 1; i <= totalSections; i++) {
      collapsedSections[`${i}`] = false;
    }

    return {
      collapsedSections,
      isCollapsed: true,
      arrowDownIcon: `
      <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.6 7.46L11.17 12.89a1.5 1.5 0 01-2.34 0L3.4 7.46" stroke="var(--sub-text-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `,
      arrowRightIcon: `
  <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.46 3.4L12.89 8.83a1.5 1.5 0 010 2.34L7.46 16.6" stroke="var(--sub-text-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`,

      arrowUpIcon: `
        <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.3999 12.543L8.83324 7.10966C9.4749 6.468 10.5249 6.468 11.1666 7.10966L16.5999 12.543"
            stroke="var(--sub-text-color)"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      `,
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      transaction_id: "",
      amount: "",
      mb8Balance: "0",
      loading: "",
      pro: {},
      bonus_code: "",
      errorDisplayed: false,
      withdraw_amount: "",
      new_referral_code: "",
      errors: [],
      code: "",
      link: "",
      copyText: "Copy",
      copyBookingCode: "Click to copy",
      // myProfile: this.getProfile(),
      depositPage: true,
      withdrawalPage: false,
      // myBonusBalance: 0,
      showSuccessModal: false,
      supportLinks: [
        {
          title: `${this.$t("emailUs")}`,
          subtitle: "hello@chopbet.ci",
          icon: "/menu/sms.svg",
          route: "mailto:hello@chopbet.ci",
        },
        // {
        //   title: `${this.$t("phoneNumber")}`,
        //   subtitle: '+225 990 889 1231',
        //   icon: '/menu/phone.svg',
        //   route: 'tel:+2259908891231',
        // },
      ],
      socialLinks: [
        {
          title: "Twitter",
          icon: "/menu/twitter.svg",
          route: "https://x.com/Choplife_CI?t=ViuwoktlWf_3C6m6Ke0_QA&s=09",
        },
        {
          title: "Facebook",
          icon: "/menu/facebook.svg",
          route: "https://m.facebook.com/choplife.ci/",
        },
        {
          title: "Instagram",
          icon: "/menu/instagram.svg",
          route:
            "https://www.instagram.com/choplife.ci_?igsh=MTl3YnBqZHRxdzFveQ==",
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "My Chopbet.com Account";
        document.description = "Manage Chopbet.com account";
      },
    },
    new_referral_code: function (n) {
      console.log("got new value here " + n);

      this.validateReferralLink();
    },
  },
  created() {
    var profile = this.getProfile();

    // Check if the user is logged in
    var isLoggedIn = profile && profile.a;

    if (isLoggedIn) {
      this.EventBus.$on("mb8:updated", (mb8balance) => {
        this.receivedMB8Balance = mb8balance;
      });
      this.EventBus.$on("bonus:updated", (bonus) => {
        this.receivedBonus = bonus;
      });
    }
  },
  mounted() {
    window.$crisp.push(["on", "chat:closed", this.handleChatClose]);
    this.$store.dispatch("setCurrentPage", "setting");
    // var vm = this;

    // vm.myProfile = vm.getProfile();
    // console.log("My Profile data", vm.myProfile);
    this.reloadProfile();

    // this.getBonus();

    // if (!vm.myProfile) {
    //     this.setError("Login", this.$t("pleaseLoginProceed"));
    //     this.$router.push({ name: "login", params: {} });
    //     return;
    // }

    // setInterval(function () {
    //     vm.myProfile = vm.getProfile();
    // }, 1000);

    // this.code = vm.myProfile.referral_code;
    // this.link = vm.myProfile.referral_link;
    this.initShareInviteModal();
  },
  methods: {
    initializeCrips() {
      window.$crisp.push(["do", "chat:show"]);
      window.$crisp.push(["do", "chat:open"]);
    },
    handleChatClose() {
      window.$crisp.push(["do", "chat:hide"]);
    },
    getInitials(firstName, lastName) {
      const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
      const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
      return `${firstInitial}${lastInitial}`;
    },

    showDeposit() {
      this.depositPage = true;
      this.withdrawalPage = false;
    },
    showWithdrawal() {
      this.withdrawalPage = true;
      this.depositPage = false;
    },
    // setAmount: function (amountToAdd) {
    //   // Convert the current amount to a number
    //   let currentAmount = parseFloat(this.amount);

    //   // Check if the current amount is a valid number
    //   if (isNaN(currentAmount)) {
    //     currentAmount = 0; // Set it to 0 if it's not a valid number
    //   }

    //   // Add the new amount to the existing amount
    //   currentAmount += amountToAdd;

    //   // Update the amount with the new total
    //   this.amount = currentAmount.toFixed(2); // Limit to 2 decimal places
    // },

    setAmount: function (currentAmount) {
      this.amount = parseFloat(currentAmount).toFixed(2);
    },

    generateOrRegeneratereferralcode: function () {
      this.reset();
      this.errors = [];

      var p = this.getProfile();
      var ref = p.referral_code;
      if (!p) {
        this.setError("Login", this.$t("pleaseLoginProceed"));
        this.$router.push({ name: "login", params: {} });
        return;
      }

      return ref;
    },

    redeemBonus: function () {
      var vm = this;
      var path = process.env.VUE_APP_BASE_BONUS_URL + "/campaign/redeem";

      var payload = {
        bonus_code: String(this.bonus_code),
      };

      bonusserve
        .post(path, JSON.stringify(payload), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          console.log("Response for Bonus", res);
          // var bonusdata = res.data.data;

          vm.bonusdata = res.data.data;
          7;
        })
        .catch((err) => {
          console.log("Error", err);
          vm.loading = "";
          if (err.response) {
            // console.log(JSON.stringify(err.request));
          } else {
            vm.setError(
              `${this.$t("networkError")}`,
              `${this.$t("checkYourNetwork")}`
            );
            // console.log(JSON.stringify(err));
          }
        });
    },

    toggleCollapseFirstSection() {
      this.isCollapsed = !this.isCollapsed;
    },
    toggleCollapse(sectionId) {
      if (sectionId) {
        this.$set(
          this.collapsedSections,
          sectionId,
          !this.collapsedSections[sectionId]
        );
      }
    },

    deposit: function () {
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.setError("Login", this.$t("pleaseLoginProceed"));
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 10) {
        this.setError(
          "Invalid Amount",
          `Enter amount at least 10${this.fiatCurrency} or above`
        );
        return;
      }

      var vm = this;
      var path = "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      vm.loading = "loading";
      console.log(this.getAuth());

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.data;
          console.log(msg);
          vm.setSuccess("Success", msg);
          this.showSuccessModal = true;
          setTimeout(() => {
            this.showSuccessModal = false;
          }, 2000);
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              vm.setError(`${this.$t("sessionExpired")}`);
              vm.logout();
              return;
            } else {
              vm.setError("Failed", err.response.data.error_message);
              // console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            vm.setError(
              `${this.$t("networkError")}`,
              `${this.$t("checkYourNetwork")}`
            );
            // console.log(JSON.stringify(err.request));
          } else {
            // vm.setError(
            //   `${this.$t("networkError")}`,
            //   `${this.$t("checkYourNetwork")}`
            // );
            console.log(JSON.stringify(err));
          }
        });
    },

    jisort: function () {
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.setError("Login", this.$t("pleaseLoginProceed"));
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var vm = this;
      var path = "/deposit/reconcile";

      var data = {
        transaction_id: this.transaction_id,
      };

      vm.loading = "loading";
      console.log(this.getAuth());

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.data;
          console.log(msg);
          vm.setSuccess("Success", msg);
          this.showSuccessModal = true;
          setTimeout(() => {
            this.showSuccessModal = false;
          }, 2000);
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              vm.setError(`${this.$t("sessionExpired")}`);
              vm.logout();
              return;
            } else {
              vm.setError("Failed", err.response.data.error_message);
              // console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            vm.setError(
              `${this.$t("networkError")}`,
              `${this.$t("checkYourNetwork")}`
            );
            // console.log(JSON.stringify(err.request));
          } else {
            // vm.setError(
            //   `${this.$t("networkError")}`,
            //   `${this.$t("checkYourNetwork")}`
            // );
            console.log(JSON.stringify(err));
          }
        });
    },

    withdraw: function () {
      if (this.loading === "loading") {
        return;
      }

      var vm = this;

      setTimeout(function () {
        vm.loading = "";
      }, 5000);

      this.reset();

      var p = this.getAuth();

      if (!p) {
        this.loading = "";
        this.setError("Login", this.$t("pleaseLoginProceed"));
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.withdraw_amount < 50) {
        this.setError(
          "Invalid Amount",
          `Enter amount at least 50${this.fiatCurrency} or above`
        );
        return;
      }

      var path = "/withdraw";

      var data = {
        amount: parseInt(this.withdraw_amount),
        // msisdn: parseInt(this.msisdn),
      };

      this.loading = "loading";

      walletserve
        .post(path, data, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          console.log("Response for Withdraw", res);
          vm.loading = "";
          var msg = res.data.data;
          if (parseInt(res.data.status) === 200) {
            vm.setSuccess("Withdrawal Initiated", msg);
          }
          if (parseInt(res.data.status) === 201) {
            vm.setSuccess("Withdrawal Initiated", msg);
          }
          this.showSuccessModal = true;
          setTimeout(() => {
            this.showSuccessModal = false;
          }, 2000);
        })
        .catch((err) => {
          console.log("Error", err);
          vm.loading = "";
          var msg = err.response.data.error_message;
          if (!vm.errorDisplayed) {
            if (parseInt(err.response)) {
              vm.setError("Failed", msg);
            } else if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              vm.setError(`${this.$t("sessionExpired")}`);
              vm.logout();
            } else {
              vm.setError("Failed", msg);
              // console.log(JSON.stringify(err.response.data.message));
            }
            vm.errorDisplayed = true;
          }
        });
    },

    shareInvite: function () {
      document.getElementById("open-shareinvite").click();
    },
    copyShareBetLink: function () {
      var profile = this.getProfile();
      if (!profile) {
        return;
      }
      var link = "https://dev.chopbet.ci/join?p=" + profile.referral_code;
      this.copyToClipboard(link);
      this.copyText = "Copied";
    },

    copyCode: function () {
      var link = "accept#" + this.code;
      this.copyToClipboard(link);
      this.copyBookingCode = "Invite Code Copied";
    },

    initShareInviteModal: function () {
      var modal = document.getElementById("shareinvite-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("open-shareinvite");

      // Get the <span> element that closes the modal
      //var span = document.getElementsByClassName("sharebet-close")[0];

      // When the user clicks on the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
      };

      // When the user clicks on <span> (x), close the modal
      /*
            span.onclick = function() {
              modal.style.display = "none";
            }
            */

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };

      document.addEventListener("click", (e) => {
        if (e.target == document.querySelector("#shareinvite-modal")) {
          modal.style.display = "none";
        }
      });
    },
  },

  computed: {
    bal: function () {
      return this.formatCurrency(this.profile.b1);
    },
    bonus: function () {
      return this.formatCurrency(
        this.profile.balance + this.profile.pending_activation
      );
    },
    myBalance: function () {
      return this.formatCurrency(this.$store.state.balance);
    },
    myBonus: function () {
      return this.$store.state.bonus;
    },
    msg: function () {
      return "Karibu Chopbet.com, Come let's win BIG together.";
    },
    profile: function () {
      return this.myProfile;
    },
    // has_referral_code: function () {
    //   return this.code !== undefined && this.code.length > 3;
    // },
    shouldDisplayTrivia() {
      const now = new Date();
      const startDate = new Date("2023-11-22T12:00:00");
      const endDate = new Date("2023-11-22T15:30:00");

      return now >= startDate && now <= endDate;
    },
  },
  filters: {
    currency: function (amount) {
      if (amount == null) {
        amount = 0;
      }

      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "CFA",
      });
    },
    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }

      return parseFloat(x).toFixed(2);
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}

.open_time {
  transform: rotate(90deg);
}
.customer_content {
  padding: 8px 45px 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  span {
    color: var(--sub-text-color);
    font-size: 14px;
  }
}

.customer-support {
  padding-top: 16px;
}
.customer-support .link.social a {
  padding: 12px 20px;
}
.customer-support .link a {
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.link_div {
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.follow-up {
  padding: 12px 20px;
}
.customer-support > div {
  border-bottom: solid var(--greyText) 1px;
}

.customer-support .link a > img {
  width: 16px;
  height: 16px;
}

.customer-link {
  display: flex;
  align-items: start;
  gap: 8px;
}
.customer-link span {
  margin-top: 4px;
  font-size: 10px;
  color: var(--text-color);
}

.customer-link p {
  font-weight: 700;
  font-size: 12px;
  color: var(--text-color);
}

.cutomeer-link .social-link {
}

.social-link {
  /* outline: solid red; */
}

.link.social a {
}

.socials {
  /* outline: solid blue; */
}

.account-details {
  background: var(--white);
  padding: 0.5rem 10px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-height: calc(100vh - 160px);
}

.account-details > .card {
  background: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);
}

.list-active {
  background-color: #f08b05;
}

.title_name {
  color: #fff;
}

.parent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.parent .banner {
  width: 100%;
  /* height: 48px; */
}

.parent iframe {
  background: #000;
  border: none;
  flex-grow: 1;
}

h4 {
  position: relative;
  margin: 0;
}

.profile-info {
  background: var(--darker-gray-white);
  color: #000;
  display: flex;
  border-radius: 0.5rem;
  padding: 1rem;
  align-items: center;
  gap: 0.5rem;
}

.profile-info .menu-icon {
  width: 3rem;
  height: 3rem;
  object-fit: contain;
  object-position: center;
  background: white;
  border-radius: 9999px;
  border: solid var(--red) 1px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-info > div {
  display: flex;
  gap: 0.1rem;
  flex-direction: column;
}

.wallet-info,
.wallet-actions {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.wallet-info > div {
  flex: 1;
  background: var(--light-red);
  border-radius: 0.5rem;
  padding: 1rem;
  color: var(--darkBackground);
}

.wallet-actions > button {
  background: var(--red-400);
  border: 2px transparent solid;
  color: var(--primary);
  padding: 1rem;
  text-align: center;
  border-radius: 0.5rem;
}

.wallet-actions > button:not(.active) {
  border-color: var(--red-400);
  background: transparent;
  color: var(--red-400);
}

.wallet-form .card {
  padding: 1rem;
}

.odd-btn {
  background: var(--light-gray);
  color: var(--primary);
  border-radius: 9999px;
  width: auto;
}

.wallet-btns-grid {
  gap: 0.5rem;
  flex-wrap: wrap;
  flex-direction: row;
  width: auto;
}

.form-wrapper1 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.menu-items {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1.5rem;
}

.section-title {
  margin: 0;
  font-size: 0.8rem;
  color: white;
  font-weight: normal;
}
</style>
